import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Link } from 'gatsby';
import media from 'styled-media-query';
import { useMeasure } from 'react-use';

import Btn from '@components/common/btn';
import SimpleBtn from '../simple-btn';
import Card from './card';
import SectionTitle from '../section_title';
import Holder from './holder.tsx';

import ViewmoreSvg from '@img/svg/common/viewmore.svg';

import { color, breakpoint, size, mixin } from "@src/settings";

type Props = {
	className: string;
	title: string;
	subtitle: string;
	winSize: Array<number>;
}
const slideSettings = {
	slidesToShow: 3,
	adaptiveHeight: true,
	responsive: [
		{
			breakpoint: 1000,
			settings: {
				centerMode: true,
				slidesToShow: 2,
				slideToScroll: 1,
				adaptiveHeight: true,
				centerPadding: `${ (100 - size.baseWidth.match(/^(\d+)%/)[1])/2 }%`,
			},
		},
		{
			breakpoint: 700,
			settings: {
				centerMode: true,
				slidesToShow: 1,
				slideToScroll: 1,
				adaptiveHeight: true,
				centerPadding: `${ (100 - size.baseWidth.match(/^(\d+)%/)[1])/2 }%`,
			},
		},
	]
}
const WhatWeDo = (props) => {

	const capHList = [];
	const [capContH, update] = useState(0);
	const highest = ():number => capHList.reduce((a,b) => Math.max(a,b));

	useEffect(() => {
		update(highest);
	},[highest])

	return (
		<Cont className={ props.className }>
				{ props.winSize[0] > 1000 ? (
					<div className="titleBox">
						<div className="title">
							<h3>{ props.title }</h3>
							<p>{ props.subTitle }</p>
						</div>
						<Link
							to={ props.lang == "ja" ? "/services" : "/en/services" }
							className="viewmore"
						><ViewmoreSvg /></Link>
					</div>
				) : (
					<SectionTitle
						className="spTitle"
						title={ props.title }
						subTitle={ props.subTitle }
					/>
				)}
				<div className="index">
					<Holder
						settings={ slideSettings }
					>
						{ props.infoList.map((info,index) => {
							const [ref, { height }] = useMeasure()
							capHList.push(height)
							return (
								<Card
									className="index__item"
									key={ index }
									text={ info }
									num={ index + 1 }
									refForCap={ ref }
									capContH={ capContH}
								/>
							)
						})}
					</Holder>
				</div>
			{ props.winSize[0] < 1000 && (
				<SimpleBtn
					to={ props.lang == "ja" ? "/services" : "/en/services" }
					className="spBtn"
				/>)}
		</Cont>
	);
}

const Cont = styled.div`
  padding-bottom: 1.8rem;
	.titleBox{
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		border-bottom: 0.2rem solid #E5E5E5;
		padding-bottom: 1rem;
		margin-bottom: 3.5rem;
		.title{
			display: flex;
			align-items: center;
			h3{
				font-weight: bold;
				line-height: 1;
				svg{
					height:auto;
					width: 13.2rem;
					display: inline-block;
				}
			}
			p{
				font-size: 1.5rem;
				line-height: 1;
				display: block;
				margin-left: 2rem;
			}
		}
		.viewmore{
			display: flex;
			align-items: center;
			& > svg{
				fill: #000;
				width: auto;
				height: 1.2rem;
			}
			&:after{
				content: "";
				${ mixin.arrowSettings }
				border-color: #000;
				margin-left: 2rem;
			}
		}
	}

	.index {
		display: flex;
		&__cont{
			display: flex;
			flex-direction: row;
			align-items: stretch;
			position: relative;
		}
		&__item{
			flex: 33.3% 1 1;
			align-self: stretch;
			margin: 0 0.15rem;
		}
	}
	${ media.lessThan( `${ breakpoint.tb }px` )`
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		.spTitle{
			margin-bottom: 3rem;
		}
		.spBtn{
			margin-top: 3rem;	
			width: 30rem;
		}
	.index{
		width: 100%;
	}
	`}
	${ media.lessThan( `${ breakpoint.sp }px` )`
		padding-bottom: 0;
		.spBtn{
			width: calc(${ size.baseWidth} - 4rem*2);
			margin-top: 2rem;
		}
	.spTitle{
		& > h2 > svg{
			height: 1.8rem;
			width: auto;
		}
	}
	`}
`;

const mapStateToProps = state => ({
	winSize: state.winsizeReducer.winSize,
	lang: state.langReducer.language,
})

export default connect(mapStateToProps)(WhatWeDo);
