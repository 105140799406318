import React from 'react';
import { connect } from "react-redux"
import styled from 'styled-components';
import media from 'styled-media-query';

import Head  from '@components/common/head';
import Hero from '@components/sitetop/hero';
import SectionTitle from '@components/sitetop/section_title';
import AboutUs from '@components/sitetop/about_us';
import WhatWeDo from '@components/sitetop/what_we_do/';
import Column from '@components/sitetop/column';
import Our_customer from '@components/sitetop/our_customer';
import NewsList from '@components/common/news-list';

import Btn from '@components/common/btn';

import Ttl__aboutus from '@img/svg/sitetop/ttl__aboutus.svg';
import Whatweare from '@img/svg/sitetop/ttl__whatweare.svg';
import Ttl__fv from '@img/svg/sitetop/ttl__fv.svg';
import Ttl__ourstrength from '@img/svg/sitetop/ttl__ourstrength.svg';
import Ttl__ourservice from '@img/svg/sitetop/ttl__ourservices.svg';
import Whatwedo from '@img/svg/sitetop/ttl__whatwedo.svg';
import Ttl__whatisnews from '@img/svg/sitetop/ttl__whatisnews.svg';
import { HeroTitle, OurCustomers } from '@components/sitetop/svgs';

import Layout from '@components/common/layout';

import { size, mixin, breakpoint } from '@src/settings';

const IndexPage: React.FC = ({ winSize, data }) => {

	const posts = [];
	data.posts.edges.map(post => {
		posts.push({
			date: post.node.date,
			title: post.node.title,
			to: `/en/news/${ post.node.wordpress_id }`
		});
	});

	return (
		<PageCont>
				<Head />
				<Hero
					className="hero"
					title={<HeroTitle style={{ fill: "#FFF", }} />}
					desc={<>With thorough quality control and proactive response, we provide reliable and flexible services</>}
					image={ data['img_hero'].childImageSharp.fluid }
				/>
				<SectionTitle
					className="sectionTitle title_whoWeAre"
					title={<Whatweare />}
					align="center"
				/>

				<AboutUs 
					className="aboutUs"
					title={<Ttl__aboutus />}
					subTitle={<>Technology Drives Tomorrow</>}
					desc={<>With human development as our foundation, believing human inspired Technology Drives Tomorrow, we never stop to innovate technology and deliver trustworthy products and services to the major automobile manufacturers worldwide.</>}
					img={  data[ winSize[0] > breakpoint.tb ? 'img_aboutus' : 'img_aboutus_sp' ].childImageSharp.fluid }
				/>

				<WhatWeDo
					className="whatWeDo"
					title={<Whatwedo />}
					infoList={
						[
							{
								en: "IKKANSEISAN System",
								cap: "From the Development of Prototype and Tool & Die, to the Production of Stamping, Welding, and Assembly, all process are accomplished within the same factory. We are the only company in Malaysia that specialized in IKKANSEISAN System, enable us to solve issues efficiently and improve quality effectively.",
								to: "/flow",
							},
							{
								en: "OUR PRODUCT",
								cap: "We manufacture automobile parts of various sizes and shapes, including Outer Panel parts, Shell Body parts, Under Body parts, Assembly parts, and Precision Machined parts. We also provide customized design and manufacturing of equipments or parts according to customer's request.",
								to: "/products"
							},
							{
								en: "QUALITY",
								cap: "We adopt and embrace Toyota Production System (TPS) as our business guidelines. For example continuous improvement on quality and productivity, only produce the right product of the right quantity at the right time. On top of that, we also endeavor to create sustainable working environment and continually contribute to the society. ",
								to: "/quality"
							}
						]
					}
				/>

				<Column
					className="columns"
					img_pc={ data['strengthIntro'].childImageSharp.fluid }
					img_sp={ data['strengthIntro_sp'].childImageSharp.fluid }
					title={<Ttl__ourstrength />}
					desc="Specializing in IKKANSEISAN System and implementing TPS with a global perspective, we are always passionate to pursue better quality, on-time delivery and cost competitiveness to meet customer's satisfaction.
	As a pioneer in the body parts manufacturing field, we will continue to grow our people and to contribute to the Automotive Industry."
					to="/en/strength" 
				/>

				<Column
					className="columns"
					img_pc={ data['serviceIntro'].childImageSharp.fluid }
					img_sp={ data['serviceIntro_sp'].childImageSharp.fluid }
					title={<Ttl__ourservice />}
					desc="As a Tier-1 supplier to the major Automobile Manufactuer, we will continue to lead in providing expertise and services precisely needed in the field."
					to="/en/services" 
					textBg="black"
					reversed={ true }
					isBottom={ true }
				/>

				<Our_customer
					className="ourCustomer"
					title={<OurCustomers />}
					data={data}
					logoList={[
						'Custumer_perodua',
						'Custumer_toyota',
						'Custumer_toyota_ab',
						'Custumer_proton',
						'Custumer_akashikikai',
						'Custumer_miyazu',
						'Custumer_isuzu',
						'Custumer_epmb',
					]}
				/>
				<section className="news">
					<SectionTitle
						className="sectionTitle"
						title={<Ttl__whatisnews />}
					/>
					<NewsList
						posts={ posts }
					/>
				</section>
		</PageCont>
	)
}

const PageCont = styled(Layout)`
	.hero{
		margin-bottom: 10rem;
	}
	.sectionTitle{
		margin-bottom: 5rem;
	}
	.aboutUs{
		${ mixin.baseWidthSet }
		margin-bottom: 5.8rem;
	}
	.whatWeDo{
		${ mixin.baseWidthSet }
		margin-bottom: 10rem;
	}
	.columns{
		${ mixin.baseWidthSet }
	}
	.ourCustomer{
		${ mixin.baseWidthSet }
		margin: 11rem auto;
	}
	.news{
    margin-top: 4rem;
    padding: 11rem 0 13rem;
    background: #EBEFF4;
	}
	.newsList{
		${ mixin.baseWidthSet }
	}
	${ media.lessThan(`${ breakpoint.tb }px`)`
	.title_whoWeAre{
		color: #FFF;
		& > .title > svg{
			fill: #FFF;
		}
	}
	.columns + .columns{
		margin-top: 8rem;
	}
	.news{
		padding: 8rem 0;
	}
	.aboutUs{
		margin-bottom: 7rem;
	}
	.whatWeDo{
		width: 100%;
		max-width: inherit;
	}
	`}
	${ media.lessThan(`${ breakpoint.sp }px`)`
		.hero{
			margin-bottom: 7rem;
		}
		.columns + .columns{
			margin-top: 6rem;
		}
		.ourCustomer{
			margin: 7rem auto 6rem;
		}
	`}
`

export const query = graphql`
	query sitetop_en {
		img_hero:file(relativePath: {eq: "sitetop/hero-img_200629.jpg"}) {
			childImageSharp {
				fluid(maxWidth: 1350, quality: 90) {
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},
		img_aboutus:file(relativePath: {eq: "sitetop/aboutintro.jpg"}) {
			childImageSharp {
				fluid(maxWidth: 800, quality: 90) {
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},
		img_aboutus_sp:file(relativePath: {eq: "sitetop/aboutintro--sp.jpg"}) {
			childImageSharp {
				fluid(maxWidth: 820, quality: 90) {
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},
		serviceIntro: file( relativePath: { eq: "sitetop/serviceintro.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},
		serviceIntro_sp: file( relativePath: { eq: "sitetop/serviceintro--sp.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		}
		strengthIntro: file( relativePath: { eq: "sitetop/strengthintro.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},
		strengthIntro_sp: file( relativePath: { eq: "sitetop/strengthintro--sp.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},
		Custumer_perodua: file( relativePath: { eq: "sitetop/custumer-perodua.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Custumer_isuzu: file( relativePath: { eq: "sitetop/custumer-isuzu.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Custumer_proton: file( relativePath: { eq: "sitetop/custumer-proton.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Custumer_epmb: file( relativePath: { eq: "sitetop/custumer-epmb.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Custumer_miyazu: file( relativePath: { eq: "sitetop/custumer-miyazu.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Custumer_toyota: file( relativePath: { eq: "sitetop/custumer-toyota.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Custumer_toyota_ab: file( relativePath: { eq: "sitetop/custumer-toyota-ab.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},
		Custumer_akashikikai: file( relativePath: { eq: "sitetop/custumer-akashikikai.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},
		posts: allWordpressPost(filter: {categories: {eq: 5}}, sort: {fields: date}) {
			edges {
				node {
					date(formatString: "Y.M.D")
					title
					wordpress_id
				}
			},
		}
	}
`

const mapStateToProps = state => ({
	winSize: state.winsizeReducer.winSize,
})
export default connect(mapStateToProps)(IndexPage);
