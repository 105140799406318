import React from 'react';
import styled from 'styled-components';
import media from "styled-media-query";
import { Link } from 'gatsby';

import Svg_viewmore from '@img/svg/common/viewmore.svg';
import { color, breakpoint, mixin } from "@src/settings";

interface Props {
	className: string;
	children?: React.ReactElement | string;
	to?: string;
}
const SimpleBtn = (props: Props) => {
	return (
		<Cont
			className={ props.className }
			to={ props.to }
			isSkelton={ props.isSkelton }
		>
			{ props.children }	
		</Cont>
	)
}

SimpleBtn.defaultProps = {
	children: <Svg_viewmore/>,
}

const size = { 
	size_btn_fontsize: "1.4rem",
	size_btn_width:"22rem",
	size_btn_height:"4.2rem",
}

const Cont = styled((props) => {
	if(props.to){
		return (
			<Link
					to={ props.to }
					children={ props.children }
					className={ props.className }
			/>
		)	
	} else {
		return (
			<div
				children={ props.children }
				className={ props.className }
				/>
		);
	}
})`
	display: flex;
	justify-content: justify;
	background-color: transparent;
	min-width: 22rem;
	padding: 1.2rem 0 1.3rem;
	color: #000;
	text-align: left;
	outline: none;
	line-height: 1.3;
	font-size: 1.4rem;
	position: relative;
	border-bottom: 2px solid #000;
	&:after{
		content: "";
		display: block;
		position: absolute;
		${ mixin.arrowSettings }
		border-bottom-color: #000;
		border-right-color: #000;
		top: 50%;
		right: 0.5rem;
		transform: translateY(-50%) rotate(-45deg);
	}
	& > svg {
		flex: auto 0 0;
		display: block;
		width: auto;
		height: 1.2rem;
		fill: #000;
	}

${media.lessThan( `${ breakpoint.sp }px` )`
	.mainBtn{
		display:flex;
		align-items:center;
		justify-content:space-between;
		svg{
			width: 55%;
		}
	}
`}
`;

export default SimpleBtn;

