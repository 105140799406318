import React from 'react';
import styled from 'styled-components';
import media from "styled-media-query";
import Img from "gatsby-image";

import SectionTitle from "../sitetop/section_title" 

import { breakpoint, color, size } from "@src/settings";

const Our_customer = (props) => (
	<Cont className={ props.className }>
	  <SectionTitle
			title={ props.title }
			subTitle={ props.subTitle }
	  />
	  <div className="list">
		{ props.logoList.map((logo,index) => {
		  return (
			<Img
			  className="item"
			  fluid={ props.data[logo].childImageSharp.fluid }
			  fade={ true }
			  key={ index }
			/>
		  )
		})}
	  </div>
	</Cont>
);

//style
const Cont = styled.div`
  border: 2rem solid #FFF; 
  background: ${ color.paleGray };
  padding: 8rem 6rem 10rem;
  .list{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	margin-top: 5rem;
  }
  .item{
		flex-shrink: 0;
		flex-grow: 0;
  }
	${ media.greaterThan( `${ breakpoint.tb }px` )`
		.item{
			flex-basis: calc((100% - 5rem*3)/4);
			&:not(:nth-child(4n + 4)){
				margin-right: 5rem;
			}
			&:not(:nth-child(-n + 4)){
				margin-top: 5rem;
			}
		}
	`}

	${media.lessThan( `${ breakpoint.tb }px` )`
		${ media.greaterThan( `${ breakpoint.sp }px` )`
			.item{
				flex-basis: calc((100% - 5rem*2)/3);
				&:not(:nth-child(3n + 3)){
					margin-right: 5rem;
				}
				&:not(:nth-child(-n + 3)){
					margin-top: 5rem;
				}
			}
		`}
	`}
	${ media.lessThan( `${ breakpoint.sp }px` )`
		border-width: 1rem;
		padding: 4rem 3rem 3rem;
		.list{
			margin-top: 2.5rem;
			justify-content: flex-start;
			padding: 1rem;
		}
		.item{
			flex-basis: calc((100% - 2rem)/2);
			&:not(:nth-child(2n + 2)){
				margin-right: 2rem;
			}
			&:not(:nth-child(-n + 2)){
				margin-top: 2rem;
			}
		}
	`}
`;

export default Our_customer;

