import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import media from "styled-media-query";

import { breakpoint } from "@src/settings";

type Props = {
	className: string;
	title: React.ReacrElement | string;
	subtitle?: React.ReacrElement| string;
	align: "left" | "center";
	lang: "ja" | "en";
}

const SectionTitle: React.FC<Props> = props => (
	<Cont
		className={ props.className }
		align={ props.align }
	>
		 <h2 className="title">{ props.title }</h2>
		{ props.lang == "ja" && (
			<p className="subTitle">{ props.subTitle }</p>
		)}
	</Cont>
);

SectionTitle.defaultProps = {
	align: "center",
}

const Cont = styled.div`
	display: flex;
	flex-direction: ${ props => props.isInline ? 'row' : 'column' };
	align-items: ${ props => { switch(props.align){
		case "left": 
			return "flex-start";
			break;
		default:
			return "center";
			break;
	}}};
	& > .title{
		font-size: 2.1rem;
		line-height: 1.3;
		& > svg{
				width: auto;
				height: 2.3rem;
		}
	}
	& > .subTitle{
		margin-top: 0.7rem;
		font-size: 1.5rem;
		line-height: 1.3;
	}

	${media.lessThan( `${ breakpoint.sp }px` )`
		& > .title{
			&,
			& > svg{
				max-width: 100%;
			}
			& > svg{
				height: 1.9rem;
				width: auto;
			}
		}
	`}
`;

const mapStateToProps = (state, props) => ({
	lang: state.langReducer.language,
})
export default connect(mapStateToProps)(SectionTitle);
