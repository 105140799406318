import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import media from 'styled-media-query';
import Img from 'gatsby-image/withIEPolyfill'
import SpDeco from './sp_deco';
import { breakpoint } from '@src/settings';

type Props = {
	image: string;
	className?: string;
	winSize: Array<number>;
}

const HeroImg: React.FC<Props> = props => (
	<Cont
		className={ props.className }
		winSize={ props.winSize }
	>
		<Img
			className="img"
			fluid={ props.image }
			fade={ true }
			objectFit="cover"
			objectPosition="50% 50%"
		/>

		{ props.winSize[0] < 1000 && <SpDeco className="spDeco" /> }

	</Cont>
)

type ContProps = {
	winSize: Array<number>;	
}
const Cont = styled.div<ContProps>`
	overflow: hidden;
	position: relative;
	& > .img{
		width: 100%;
		height: ${ props => props.winSize[0]*0.52 }px;
	}
	${ media.lessThan(`${ breakpoint.tb }px`)`
		position: relative;
		& > .img{
			height: ${ props => props.winSize[0]*0.75 }px;
		}
		.spDeco{
			position: absolute;
			bottom: ${ props => props.winSize[0] > breakpoint.sp ? props.winSize[0] *0.05 : props.winSize[0]*-0.0}px;
			right: 0;
			z-index: 1;
		}
		&:after{
			content: "";
			display: block;
			width: 100%;
			height: ${ props => props.winSize[0]*0.2}px;
			background-image: linear-gradient(0deg, #FFF, transparent 100%);
			background-size: 100% 100%;
			position: absolute;
			bottom: -1px;
			left: 0;
			z-index: 0;
			mix-blend-mode: luminosity;
		}
	`}
	${ media.lessThan(`${ breakpoint.sp }px`)`
		& > .img{
			height: ${ props => props.winSize[0]*0.8 }px;
		}
	`}
`
const mapStateToProps = state => ({
	winSize: state.winsizeReducer.winSize,
})

export default connect(mapStateToProps)(HeroImg);
