//base
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Link } from 'gatsby';
import media from "styled-media-query";

import { breakpoint } from "@src/settings";

import Bg from '@components/common/bg';
import HeroImg from './hero_img';
import Btn from '@components/common/btn';
import { color } from '@src/settings'

type Props = {
	className: string;
	winSize: Array<number>,
lang?: "ja" | "en",
}

const Hero: React.FC<Props> = (props) => (
	<Cont className={ props.className } winSize={ props.winSize }>
		<div className="container">
			<HeroImg className="heroImg" image={props.image}/>
			{ props.winSize[0] > 1000 && <Bg className="bg" /> }
			<div className="txt">
				<div className="title">{ props.title}</div>
				<p className="desc">{ props.desc }</p>
			</div>
			<Btn className="btn" to={ props.lang == "ja" ? "/strength" : "/en/strength" }/>
		</div>
	</Cont>
);

const Cont = styled.div`
	& > .container{
		overflow: hidden;
		position: relative;
		& > .heroImg{
			position: relative;
			z-index: 0;
		}
		& > .bg{
			z-index: 1;
		}
		& > .txt{
			width: 37rem;
			position: absolute;
			top: 50%;
			right: 5rem;
			z-index: 2;
			transform: translateY(-57%);
			& > .title{
				margin-bottom: 1.8rem;
				svg{
					width:100%;
					height:auto;
				}
			}
			& > .desc{
				font-size: 1.9rem;
				font-weight: 300;
				line-height: 1.9;
				letter-spacing: 0.08em;
				color: #FFF;
			}
		}
		& > .btn{
			width: 22rem;
			bottom: 6.8rem;
			position: absolute;
			right: 5rem;
			text-align: right;
			z-index: 30;
		}
	}

	${media.lessThan( `${ breakpoint.tb }px` )`
		.container{
			background-color: ${ color.blue };
			padding-bottom: ${ props => props.winSize[0] * 0.1}px;
			&:after{
				content: "";
				display: block;
				width: 1.6rem;
				height: 1.6rem;
				border-bottom: 3px solid #FFF;
				border-right: 3px solid #FFF;
				position: absolute;
				left: 50%;
				bottom: 4rem;
				transform: translate(-50%, 50%) rotate(45deg);
			}
			& > .txt{
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				width: 75%;
				max-width: 40rem;
				margin: 0 auto;
				padding-top:20px;
				position: static;
				top: auto;
				right: auto;
				transform: inherit;
				margin: ${ props => props.winSize[0]*-0.13 }px auto 1.5rem;
				& > .title{
					position: relative;
					z-index: 2;
					svg{
						display:block;
						margin:0 auto;
					}
				}
				& > .desc{
					color: #FFF;
					display: inline-block;
					font-size: 1.6rem;
					line-height: 2;
					text-align: left;
					position: relative;
					z-index: 2;
				}
			}
			& > .btn{
				position: relative;
				bottom: inherit;
				right: inherit;
				z-index: 2;
				width: 75%;
				max-width: 40rem;
				margin: 0 auto;
			}
		}
	`}
	${ media.lessThan( `${ breakpoint.sp}px` )`
		& > .container{
			padding-bottom: ${ props => props.winSize[0]*0.2 }px;
			& > .txt{
				margin: ${ props => props.winSize[0]*-0.17 }px auto 1.5rem;
			}
		}
	`}

`;

const mapStateToProps = state => ({
	winSize: state.winsizeReducer.winSize,
	lang: state.langReducer.language,
})
export default connect(mapStateToProps)(Hero);

