import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Link } from 'gatsby';
import media from "styled-media-query";
import BtnBase from "@components/common/btn"

import { color, breakpoint, size, mixin } from "@src/settings";

const NewsListBox = ( props ) => (
	<Cont className={ props.className }>
		{ props.children }
		{ !props.noBtn && (
			<Btn to={ props.lang == "ja" ? "/news" : "/en/news/" }>{ props.btnLabel }</Btn>
		)}
	</Cont>
);

const contPaddingH = 5;

const Cont = styled.div`
	position: relative;
	background-color: #FFF;
	padding: 3rem ${ contPaddingH }rem;
	${ mixin.baseWidthSet }
	${media.lessThan( `${ breakpoint.sp }px` )`
		padding: 3rem;
	`}
`;

const Btn = styled(BtnBase)`
	position: absolute;
	right: ${ contPaddingH }rem;
	bottom: 0;
	transform: translateY(50%);
	min-width: 22rem;
	${media.lessThan( `${ breakpoint.tb }px` )`
		${ mixin.spBtnStyleOfSitetop }
	`}
`;

const mapStateToProps = (state,props) => ({
	lang: state.langReducer.language,
})
export default connect(mapStateToProps)(NewsListBox)
