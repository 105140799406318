import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import media from "styled-media-query";
import Img from 'gatsby-image/withIEPolyfill'
import Btn from '@components/common/btn';
import SectionTitle from './section_title'

import { color, breakpoint, size, mixin } from '@src/settings';

type Props = {
	textBg?: "black"
}
const Column = (props) => (
	<Cont
		className={ props.className }
		textBg={ props.textBg}
		reversed={ props.reversed }
		isBottom={ props.isBottom }
	>
		<Img className="img" fluid={ props.winSize[0] > breakpoint.tb ? props.img_pc : props.img_sp } fade={ true } />
		<div className="txt">
			<SectionTitle
				className="titleBlock"
				title={ props.title }
				subTitle={ props.subTitle }
				align="left"
			/>
			<p className="desc">{ props.desc }</p>
			<Btn className="btn"
				to={ props.to }
				isSkelton={ props.textBg == "black" && props.winSize[0] > breakpoint.tb ? true : false }
			/>
		</div>
	</Cont>
);

const gap = 7;
const basePaddingV = 8;
const Cont = styled.div`
	display: flex;
	align-items: stretch;
	flex-direction: ${ props => props.reversed ? "row-reverse" : "row"};
	& > *:nth-child(${ props => props.reversed ? 1 : 2 }){
		transform: translateY(7rem);
		margin-bottom: ${ props => props.isBottom ? `${gap}rem` : 0 };
	}
	.titleBlock{
		width: 100%;
	}
	.txt{
		flex: 50% 0 0;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		position: relative;
		background: #FFF;
		padding: ${ basePaddingV }rem 6rem;
		width: 51rem;
		color: ${ props => props.textBg == "black" ? "#FFF" : "inherit" };
		background-color: ${ props => props.textBg == "black" ? "#000" : "#FFF" };
		&:nth-child(${ props => props.reversed ? 2 : 1 }){
			padding-top: ${ props => props.isBottom ? `${ basePaddingV + gap }rem` : 0 };
		}
	}
	.img{
		flex: 50% 0 0;
	}
	.desc{
			font-size: 1.6rem;
			line-height: 1.7;
			color: ${ props => props.textBg == "black" ? "#FFF" : color.txtLight };
			margin-top: 2rem;
	}
	.btn{
			width:22rem;
			margin-top: 2.8rem;
	}

	${media.lessThan( `${ breakpoint.tb }px` )`
		display: block;
		& > *:nth-child(${ props => props.reversed ? 1 : 2 }){
			transform: none;
			margin-bottom: 0;
		}
		.txt{
			position: relative;
			width: 100%;
			&:nth-child(${ props => props.reversed ? 2 : 1 }){
				padding-top: ${ basePaddingV }rem;
			}
		}
		.btn{
			margin: 0;
			${ mixin.spBtnStyleOfSitetop }
		}
	`}
	${ media.lessThan(`${ breakpoint.sp }px`)`
		.txt{
			&,
			&:nth-child(${ props => props.reversed ? 2 : 1 }){
				padding-top: ${ basePaddingV }rem;
				padding: 3.5rem 3.5rem 5rem;
			}
		}
	`}
`;

const mapStateToProps = state => ({
	winSize: state.winsizeReducer.winSize,
})
export default connect(mapStateToProps)(Column);
