import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { Link as LinkBase } from 'gatsby';
import { color, mixin, breakpoint } from '@src/settings';

export const Txt = styled.div`
	display: flex;
	margin-right: 1.5rem;
	${media.lessThan( `${ breakpoint.tb }px` )`
		display: block;
	`}
`;

export const Link = styled(LinkBase)`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 2.5rem 0;
	font-size: 1.7rem;
	line-height: 1.3;
	&:after{
		content: "";
		display: block;
		${ mixin.arrowSettings }
		border-color: #000;
		margin-right: 1rem;
	}
	& + &{
		border-top: 1px solid #E0E0E0;
	}
`;

export const Title = styled.p`
	margin-left: 3rem;
	font-weight: 500;
	${media.lessThan( `${ breakpoint.tb }px` )`
		margin-left: 0;
	`}
`;

export const PostDate = styled.time`
	color: ${ color.red };
	font-weight: bold;
	${ media.lessThan( `${ breakpoint.tb }px` )`
		display: block;
		margin-bottom: 1rem;
	`}
`;
