import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { Link } from 'gatsby';
import { color, breakpoint } from '@src/settings';

const Card = (props) => (
	<Cont className={ props.className }>
			<Link to={ props.text.to } className="wrap">
					<p className="num">{ '0' + String(props.num)  }</p>
					<p className="en">{ props.text.en }</p>
					<p className="ja">{ props.text.ja }</p>
				<div style={{ height: `${ props.capContH }px` }}>
						<p
							className="cap"
							ref={ props.refForCap }
						>{ props.text.cap }</p>
					</div>
			</Link>
	</Cont>
);


const Cont = styled.li`
	background: #FFF;
	box-shadow: 0px 0px 0.5rem 0px rgba(0, 0, 0, 0.1);
	height: 100%;
	list-style: none;
	.wrap{
		display: block;
		padding: 3.5rem 3.5rem 4rem;
		height: 100%;
	}
	.num{
			font-size: 2.0rem;
			color: ${color.red};
			margin-bottom: 1.9rem;
	}
	.en{
			font-size: 1.7rem;
			line-height: 1.3;
			font-weight: bold;          
			margin-bottom: 0.5rem;
	}
	.ja{
			font-size: 1.4rem; 
			line-height: 1.3;
			margin-bottom: 1.7rem;
	}
	.cap{
			font-size: 1.3rem;
			line-height: 1.7;
	}
`

export default Card;
