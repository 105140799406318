import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import NewsListBox from '@components/common/news-list-box';

import { Txt, Link, Title, PostDate } from './styles';
import { color, breakpoint, size, mixin } from '@src/settings';

type Props = {
	noBtn?: boolean,
	className?: string,
}

const NewsList = ( props ) => (
	<NewsListBox noBtn={ props.noBtn } className={ props.className }>
		{ props.posts.map( (post,index) => {
			if( props.max && index+1 > props.max) return;
			return (
				<Link
					to={ post.to }
					className="item"
					key={ index }
				>
					<Txt>
						<PostDate>{ post.date }</PostDate>
						<Title>{ post.title }</Title>
					</Txt>
				</Link>
			)
		})}
	</NewsListBox>
);

export default NewsList;

