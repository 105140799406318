import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Link, graphql, StaticQuery } from 'gatsby';
import media from 'styled-media-query';
import Img from 'gatsby-image/withIEPolyfill'

import Btn from '@components/common/btn'
import { color, breakpoint, size, mixin } from '@src/settings';

interface Props {
	className: string
}
const About_us = (props: Props) => (
	<Cont
		to={ props.lang == "ja" ? "/company" :"/en/company" }
		className={ props.className }
	>
		<div className="txt">
			<h3 className="title">{ props.title }</h3>
			<div className="desc">
				<p className="subTitle">{ props.subTitle }</p>
				<p className="cap">{ props.desc }</p>
			</div>
		</div>
		<Img
			className="img"
			fluid={ props.img }
			fade={ true }
			objectFit="cover"
			objectPosition="50% 100%"
		/>
		<Btn className="btn"/>
	</Cont>
);

//style
const Cont = styled(Link)`
	display: flex;
  background: #FFF;
	position: relative;
	& > .txt{
		flex: 35% 0 0;
		padding: 5rem 4rem 4.5rem;
		& > .title {
			display: block;
			margin: 0;
			line-height: 0;
			position: relative;
			padding-bottom: 2.2rem;
			margin-bottom: 5rem;
			& > svg{
				height: 1.5rem;
				width: auto;
			}
			&:after {
				content: "";
				position: absolute;
				left: 0.1rem;
				width: 2rem;
				width: 2rem;
				height: 0.2rem;
				background: ${ color.red};
				bottom: 0;
			}
		}
		& > .desc{
			& > .subTitle {
				font-size: 1.6rem;
				line-height: 1.6;
				font-weight: bold;
				margin-bottom: 1.5rem;
				letter-spacing: 0.03em;
			}
			& > .cap {
				font-size: 1.4rem;
				line-height: 1.7;
				color: ${ color.txtLight };
			}
		}
	}
	& > .img{
		flex: auto 1 1;
		align-self: stretch;
  }
	& > .btn{
		position: absolute;
		bottom: 4.6rem;
		right: 0;
		width: 22rem;
	}


	${media.lessThan( `${ breakpoint.tb }px` )`
		flex-direction: column-reverse;
		position: relative;
		& > .txt{
			& > .title{
			display: none;
			}
		}
		& >.btn{
			${ mixin.spBtnStyleOfSitetop }
		}
	`}
	${media.lessThan( `${ breakpoint.sp }px` )`
		& > .txt{
			padding: 3rem 3rem 4rem;
			& > .desc{
				.subTitle{
					margin-bottom: 0.8rem;
				}
			}
		}
	`}
`;

const mapStateToProps = (state,props) => ({
	lang: state.langReducer.language,
})

export default connect(mapStateToProps)(About_us)
