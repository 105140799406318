import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import { size, breakpoint } from '@src/settings';

type Props = {
	children: React.ReactElement,
	settings: object,
	winSize: Array<number>
}

const Holder: React.FC = props => {
	if( typeof window === 'undefined' ){
		return null;
	}
	return (
		<Cont className={ props.className } winSize={ props.winSize }>
			<Slider { ...props.settings } >
				{ props.children }
			</Slider>
		</Cont>
	)
}
const Cont = styled.div`
	width: 100%;
	position: relative;
	.slick-list{
		overflow: visible;
	}

	.slick-arrow{
		position: absolute;
		top: 0;
		bottom: 0;
		z-index: 1;
		width: ${ (100 - size.baseWidth.match(/^(\d+)%/)[1])/2 }%;
		color: transparent;
		line-height: 0;
		outline: none;
		&:before{
			content: "";
			display: block;
			width: 2rem;
			height: 2rem;
			border-bottom: 0.3rem solid #000;
			border-right: 0.3rem solid #000;
			position: absolute;
			top: 50%;
		}
	}
	.slick-prev{
		left: 0;
		&:before{
			left: 50%;
			transform: translate(-50%,-50%) rotate(135deg);
		}
	}
	.slick-next{
		right: 0;
		&:before{
			right: 50%;
			transform: translate(50%,-50%) rotate(-45deg);
		}
	}
	${ media.lessThan( `${ breakpoint.tb }px` )`
		.slick-list{
			overflow: hidden;
		}
	`}
`
export default Holder;
