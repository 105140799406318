import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useSpring, animated, config, interpolate } from 'react-spring';
import styled from 'styled-components';
import { color, breakpoint } from '@src/settings'

const SpDeco = props => {

	const [animProps,set] = useSpring(() => ({
		config: { tensiton: 100, friction: 100 },
		scrollY: 0,
	}))
	const handleScroll = () => {
		const scrollY = window.pageYOffset;
		set({...animProps, scrollY: scrollY})
	}
	const calc = (speed) => ( v => `translate3d(0, ${ v/props.winSize[0]*100*speed }px, 0)`)
	useEffect(() => {
		window.addEventListener('scroll',handleScroll)
	},[]);

	return (
		<Cont
			winSize={ props.winSize }
		>
			<animated.div
				className="deco"
				style={{ transform: animProps.scrollY.interpolate(calc(-0.6)) }}
			/>	
		</Cont>
	)
}
const Cont = styled.div`
	position: relative;
	z-index: 1;
	mix-blend-mode: multiply;
	.deco{
		display: block;
		width: 0;
		height: 0;
		border-style: solid;
		border-color: transparent ${ color.blue };
		border-width: 0;
		border-top-width: ${ props => props.winSize[0]*0.6 }px;
		border-right-width: ${ props => props.winSize[0] > breakpoint.sp ? props.winSize[0]*2.5 : props.winSize[0]*4}px;
		position: absolute;
		top: ${ props => props.winSize[0] > breakpoint.sp ? props.winSize[0]*0.6*-0.6 : props.winSize[0]*0.6*-0.5 }px;
		right: 0;
	}
`;

const mapStateToProps = state => ({
	winSize: state.winsizeReducer.winSize,
})

export default connect(mapStateToProps)(SpDeco);
