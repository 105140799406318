import React from 'react';

export const HeroTitle = props => (
	<svg viewBox="0 0 437.9 85.3" { ...props }>
		<g>
			<path d="M8,5.5H0.3V0.9h20.3v4.5h-7.7v24.8H8V5.5z"/>
			<path d="M23.5,0h4.7v11.3c1.3-2,3.1-3.7,6.1-3.7c4.3,0,6.8,3,6.8,7.8v14.9h-4.7V16.7c0-3.1-1.5-4.8-4-4.8
				c-2.5,0-4.2,1.8-4.2,4.9v13.5h-4.7V0z"/>
			<path d="M44.5,19.4v-0.3c0-6.7,4-11.5,9.5-11.5c6,0,9.2,5,9.2,11.7c0,0.3,0,1,0,1.6H49.2c0.4,3.7,2.7,5.7,5.6,5.7
				c2.1,0,3.6-0.9,5.1-2.3l2.5,2.9c-2,2.1-4.5,3.4-7.8,3.4C48.9,30.7,44.5,26.4,44.5,19.4z M58.6,17.7c-0.3-3.5-1.8-6.1-4.6-6.1
				c-2.6,0-4.4,2.3-4.8,6.1H58.6z"/>
			<path d="M70.4,0.9h10.5c3.2,0,5.9,0.9,7.5,2.5c1.3,1.3,2,2.9,2,4.9v0.1c0,3.6-2.1,5.5-4.3,6.6c3.2,1.1,5.6,3.1,5.6,7
				v0.2c0,5.2-4.2,8-10.4,8H70.4V0.9z M85.5,9.2c0-2.5-1.8-3.9-4.9-3.9h-5.4v8.1h5.1C83.4,13.4,85.5,12,85.5,9.2L85.5,9.2z M81.1,17.5
				h-5.9v8.4h6.1c3.4,0,5.5-1.5,5.5-4.2v-0.1C86.8,19.1,84.8,17.5,81.1,17.5z"/>
			<path d="M94.1,19.4v-0.3c0-6.7,4-11.5,9.5-11.5c6,0,9.2,5,9.2,11.7c0,0.3,0,1,0,1.6H98.9c0.4,3.7,2.7,5.7,5.6,5.7
				c2.1,0,3.6-0.9,5.1-2.3l2.5,2.9c-2,2.1-4.5,3.4-7.8,3.4C98.6,30.7,94.1,26.4,94.1,19.4z M108.2,17.7c-0.3-3.5-1.8-6.1-4.6-6.1
				c-2.6,0-4.4,2.3-4.8,6.1H108.2z"/>
			<path d="M114.4,27.6l2.2-3.4c2,1.6,4.2,2.6,6.1,2.6c2,0,3.1-1,3.1-2.4v0c0-1.6-1.7-2.3-4.4-3.4
				c-3.3-1.4-6.1-2.9-6.1-6.6v-0.1c0-4,3.2-6.5,7.3-6.5c2.5,0,5.1,0.9,7.2,2.4l-2,3.5c-1.7-1.1-3.5-2-5.3-2s-2.8,1-2.8,2.2v0.1
				c0,1.5,1.8,2.2,4.4,3.4c3.4,1.6,6,3.1,6,6.6v0c0,4.4-3.3,6.8-7.6,6.8C120.1,30.7,117,29.7,114.4,27.6z"/>
			<path d="M134.2,24.9V12.1h-2.4V8.1h2.4v-6h4.7v6h5v4.1h-5V24c0,1.7,0.9,2.4,2.4,2.4c0.9,0,1.8-0.2,2.6-0.6v3.9
				c-1.1,0.6-2.3,1-3.9,1C136.7,30.6,134.2,29.2,134.2,24.9z"/>
			<path d="M154.5,7.7c0-4.4,2.1-6.5,6.1-7l0.5,2c-2.6,0.5-3.6,2-3.4,4.1h2.1v5.6h-5.4V7.7z M163.5,7.7
				c0-4.4,2.1-6.5,6.2-7l0.5,2c-2.6,0.5-3.6,2-3.4,4.1h2.1v5.6h-5.4V7.7z"/>
			<path d="M175.1,0.9h4.9v29.4h-4.9V0.9z"/>
			<path d="M184.8,0.9h4.9v14.4l11.4-14.4h5.9l-10.2,12.6l10.7,16.7h-5.8l-8.3-12.9l-3.6,4.4v8.5h-4.9V0.9z"/>
			<path d="M210.3,0.9h4.9v14.4l11.4-14.4h5.9l-10.2,12.6l10.7,16.7H227l-8.3-12.9l-3.6,4.4v8.5h-4.9V0.9z"/>
			<path d="M243.7,0.8h4.7l10.8,29.5h-5.1l-2.4-6.9h-11.4l-2.4,6.9h-4.9L243.7,0.8z M250.1,19l-4.2-11.8L241.8,19H250.1z"
			/>
			<path d="M262.5,0.9h4.5L280,20.5V0.9h4.7v29.4h-4.1l-13.3-20.2v20.2h-4.8V0.9z"/>
			<path d="M288.2,26.4l2.9-3.6c2.2,2,4.6,3.5,7.7,3.5c2.8,0,4.6-1.6,4.6-3.7v0c0-1.8-1-3.1-5.6-4.8
				c-5.6-2.1-8.5-4.2-8.5-8.9V8.8c0-4.8,3.9-8.2,9.3-8.2c3.4,0,6.5,1.1,9.2,3.4l-2.8,3.7c-2-1.6-4.2-2.8-6.6-2.8
				c-2.6,0-4.3,1.5-4.3,3.4v0c0,2.1,1.1,3.1,6.1,5.1c5.5,2.1,8,4.4,8,8.6v0.1c0,5.2-4,8.5-9.5,8.5C295,30.7,291.3,29.4,288.2,26.4z"/>
			<path d="M312.4,0.9h18.5v4.4h-13.7v8h12.1v4.4h-12.1v8.2h13.9v4.4h-18.7V0.9z"/>
			<path d="M335.7,0.9h4.9v29.4h-4.9V0.9z"/>
			<path d="M344.2,26.4l2.9-3.6c2.2,2,4.6,3.5,7.7,3.5c2.8,0,4.6-1.6,4.6-3.7v0c0-1.8-1-3.1-5.6-4.8
				c-5.6-2.1-8.5-4.2-8.5-8.9V8.8c0-4.8,3.9-8.2,9.3-8.2c3.4,0,6.5,1.1,9.2,3.4l-2.8,3.7c-2-1.6-4.2-2.8-6.6-2.8
				c-2.6,0-4.3,1.5-4.3,3.4v0c0,2.1,1.1,3.1,6.1,5.1c5.5,2.1,8,4.4,8,8.6v0.1c0,5.2-4,8.5-9.5,8.5C351,30.7,347.4,29.4,344.2,26.4z"/>
			<path d="M377.5,0.8h4.7l10.8,29.5h-5.1l-2.4-6.9H374l-2.4,6.9h-4.9L377.5,0.8z M383.9,19l-4.2-11.8L375.5,19H383.9z"/>
			<path d="M396.3,0.9h4.5l12.9,19.6V0.9h4.7v29.4h-4.1l-13.3-20.2v20.2h-4.8V0.9z"/>
			<path d="M422.3,10.5c2.6-0.5,3.6-2,3.4-4h-2.1V0.9h5.4v4.7c0,4.4-2.1,6.5-6.1,7L422.3,10.5z M431.3,10.5
				c2.6-0.5,3.6-2,3.4-4h-2.2V0.9h5.4v4.7c0,4.4-2.1,6.5-6.1,7L431.3,10.5z"/>
			<path d="M0,75l2.9-3.6c2.2,2,4.6,3.5,7.7,3.5c2.8,0,4.6-1.6,4.6-3.7v0c0-1.8-1-3.1-5.6-4.8c-5.6-2.1-8.5-4.2-8.5-8.9
				v-0.1c0-4.8,3.9-8.2,9.3-8.2c3.4,0,6.5,1.1,9.2,3.4l-2.8,3.7c-2-1.6-4.2-2.8-6.6-2.8c-2.6,0-4.3,1.5-4.3,3.4v0
				c0,2.1,1.1,3.1,6.1,5.1c5.5,2.1,8,4.4,8,8.6v0.1c0,5.2-4,8.5-9.5,8.5C6.8,79.2,3.2,77.9,0,75z"/>
			<path d="M36.3,56.6h4.9l-7.6,22.6c-1.5,4.5-3.3,6.1-6.6,6.1c-1.6,0-3.1-0.4-4.5-1.2l1.4-3.6c0.8,0.4,1.7,0.8,2.6,0.8
				c1.3,0,2-0.5,2.7-2.3l-7.9-22.3h5l5.2,16.1L36.3,56.6z"/>
			<path d="M41.6,76.1l2.2-3.4c2,1.6,4.2,2.6,6.1,2.6c2,0,3.1-1,3.1-2.4v0c0-1.6-1.7-2.3-4.4-3.4
				c-3.3-1.4-6.1-2.9-6.1-6.6v-0.1c0-4,3.2-6.5,7.3-6.5c2.5,0,5.1,0.9,7.2,2.4l-2,3.5c-1.7-1.1-3.5-2-5.3-2s-2.8,1-2.8,2.2v0.1
				c0,1.5,1.8,2.2,4.4,3.4c3.4,1.6,6,3.1,6,6.6v0c0,4.4-3.3,6.8-7.6,6.8C47.2,79.2,44.2,78.3,41.6,76.1z"/>
			<path d="M61.4,73.4V60.6H59v-4.1h2.4v-6h4.7v6h5v4.1h-5v11.8c0,1.7,0.9,2.4,2.4,2.4c0.9,0,1.8-0.2,2.6-0.6v3.9
				c-1.1,0.6-2.3,1-3.9,1C63.9,79.1,61.4,77.8,61.4,73.4z"/>
			<path d="M73,67.9v-0.3c0-6.7,4-11.5,9.5-11.5c6,0,9.2,5,9.2,11.7c0,0.3,0,1,0,1.6H77.8c0.4,3.7,2.7,5.7,5.6,5.7
				c2.1,0,3.6-0.9,5.1-2.3l2.5,2.9c-2,2.1-4.5,3.4-7.8,3.4C77.5,79.3,73,74.9,73,67.9z M87.1,66.2c-0.3-3.5-1.8-6.1-4.6-6.1
				c-2.6,0-4.4,2.3-4.8,6.1H87.1z"/>
			<path d="M95.2,56.6h4.7v3.2c1.3-2,3-3.6,6-3.6c2.7,0,4.7,1.4,5.7,3.6c1.5-2,3.5-3.6,6.5-3.6c4.1,0,6.8,2.7,6.8,7.8
				v14.9h-4.7V65.3c0-3.2-1.3-4.9-3.8-4.9c-2.3,0-4,1.8-4,4.9v13.5h-4.7V65.3c0-3.1-1.4-4.8-3.7-4.8c-2.4,0-4,1.9-4,4.9v13.4h-4.7
				V56.6z"/>
			<path d="M139.4,48.7h5v4.7h-5V48.7z M139.6,56.6h4.7v22.2h-4.7V56.6z"/>
			<path d="M151.6,56.6h4.7v3.3c1.3-2,3.1-3.7,6.1-3.7c4.3,0,6.8,3,6.8,7.8v14.9h-4.7V65.3c0-3.1-1.5-4.8-4-4.8
				c-2.5,0-4.2,1.8-4.2,4.9v13.5h-4.7V56.6z"/>
			<path d="M187.4,49.4h5.1l7.6,13.5l7.5-13.5h5.1v29.4h-4.8V58l-7.8,13.6h-0.1l-7.8-13.5v20.7h-4.7V49.4z"/>
			<path d="M218.9,72.4v-0.2c0-4.8,3.2-7.1,7.9-7.1c2.1,0,3.6,0.4,5.1,0.9v-0.9c0-3-1.8-4.5-4.8-4.5
				c-2.1,0-3.9,0.7-5.4,1.3l-1.3-3.8c2.1-1,4.4-1.8,7.3-1.8c2.9,0,5.1,0.8,6.5,2.3c1.5,1.5,2.2,3.6,2.2,6.4v13.8h-4.7v-2.7
				c-1.3,1.9-3.3,3.1-6.2,3.1C222,79.2,218.9,76.8,218.9,72.4z M232,71.3v-2.2c-1.1-0.5-2.5-0.8-4.1-0.8c-2.7,0-4.3,1.4-4.3,3.7v0.1
				c0,2.2,1.6,3.5,3.6,3.5C229.9,75.7,232,73.9,232,71.3z"/>
			<path d="M243.6,48.5h4.7v30.3h-4.7V48.5z"/>
			<path d="M254.4,72.4v-0.2c0-4.8,3.2-7.1,7.9-7.1c2.1,0,3.6,0.4,5.1,0.9v-0.9c0-3-1.8-4.5-4.8-4.5
				c-2.1,0-3.9,0.7-5.4,1.3l-1.3-3.8c2.1-1,4.4-1.8,7.3-1.8c2.9,0,5.1,0.8,6.5,2.3c1.5,1.5,2.2,3.6,2.2,6.4v13.8h-4.7v-2.7
				c-1.3,1.9-3.3,3.1-6.2,3.1C257.5,79.2,254.4,76.8,254.4,72.4z M267.4,71.3v-2.2c-1.1-0.5-2.5-0.8-4.1-0.8c-2.7,0-4.3,1.4-4.3,3.7
				v0.1c0,2.2,1.6,3.5,3.6,3.5C265.3,75.7,267.4,73.9,267.4,71.3z"/>
			<path d="M291.3,56.6h4.9l-7.6,22.6c-1.5,4.5-3.3,6.1-6.6,6.1c-1.6,0-3.1-0.4-4.5-1.2l1.4-3.6c0.8,0.4,1.7,0.8,2.6,0.8
				c1.3,0,2-0.5,2.7-2.3l-7.9-22.3h5l5.2,16.1L291.3,56.6z"/>
			<path d="M299.1,76.1l2.2-3.4c2,1.6,4.2,2.6,6.1,2.6s3.1-1,3.1-2.4v0c0-1.6-1.7-2.3-4.4-3.4c-3.3-1.4-6.1-2.9-6.1-6.6
				v-0.1c0-4,3.2-6.5,7.3-6.5c2.5,0,5.1,0.9,7.2,2.4l-2,3.5c-1.7-1.1-3.5-2-5.3-2s-2.8,1-2.8,2.2v0.1c0,1.5,1.8,2.2,4.4,3.4
				c3.4,1.6,6,3.1,6,6.6v0c0,4.4-3.3,6.8-7.6,6.8C304.8,79.2,301.7,78.3,299.1,76.1z"/>
			<path d="M321,48.7h5v4.7h-5V48.7z M321.1,56.6h4.7v22.2h-4.7V56.6z"/>
			<path d="M331.9,72.4v-0.2c0-4.8,3.2-7.1,7.9-7.1c2.1,0,3.6,0.4,5.1,0.9v-0.9c0-3-1.8-4.5-4.8-4.5
				c-2.1,0-3.9,0.7-5.4,1.3l-1.3-3.8c2.1-1,4.4-1.8,7.3-1.8c2.9,0,5.1,0.8,6.5,2.3c1.5,1.5,2.2,3.6,2.2,6.4v13.8h-4.7v-2.7
				c-1.3,1.9-3.3,3.1-6.2,3.1C335,79.2,331.9,76.8,331.9,72.4z M345,71.3v-2.2c-1.1-0.5-2.5-0.8-4.1-0.8c-2.7,0-4.3,1.4-4.3,3.7v0.1
				c0,2.2,1.6,3.5,3.6,3.5C342.9,75.7,345,73.9,345,71.3z"/>
			<path d="M356.2,73.2h5.4v5.6h-5.4V73.2z"/>
		</g>
	</svg>
)

export const OurCustomers = props => (
	<svg viewBox="0 0 292.9 23.2" { ...props }>
		<g>
			<path d="M1.3,11.7L1.3,11.7c0-6.4,5-11.7,12-11.7c6.9,0,11.9,5.2,11.9,11.6v0.1c0,6.4-5,11.6-12,11.6S1.3,18.1,1.3,11.7z M20,11.7
				L20,11.7c0-3.9-2.8-7.1-6.8-7.1s-6.7,3.1-6.7,7v0.1c0,3.9,2.8,7.1,6.8,7.1S20,15.5,20,11.7z"/>
			<path d="M29.2,13.2V0.4h4.9v12.7c0,3.7,1.8,5.6,4.8,5.6s4.8-1.8,4.8-5.4V0.4h4.9v12.7c0,6.8-3.8,10.1-9.9,10.1S29.2,19.8,29.2,13.2
				z"/>
			<path d="M53.9,0.4h10.3c2.9,0,5.1,0.8,6.5,2.3c1.3,1.3,1.9,3,1.9,5.1v0.1c0,3.6-2,5.9-4.8,7l5.5,8h-5.8l-4.8-7.2h-3.9v7.2h-4.9V0.4
				z M63.8,11.3c2.4,0,3.8-1.3,3.8-3.2V8.1c0-2.1-1.5-3.2-3.9-3.2h-4.9v6.4H63.8z"/>
			<path d="M87,11.7L87,11.7C87,5.2,91.8,0,98.7,0c4.2,0,6.8,1.4,8.9,3.5l-3.1,3.6c-1.7-1.6-3.5-2.5-5.7-2.5c-3.8,0-6.5,3.1-6.5,7v0.1
				c0,3.9,2.7,7.1,6.5,7.1c2.6,0,4.1-1,5.9-2.6l3.1,3.2c-2.3,2.5-4.9,4-9.2,4C91.9,23.2,87,18.1,87,11.7z"/>
			<path d="M111.3,13.2V0.4h4.9v12.7c0,3.7,1.8,5.6,4.8,5.6c3,0,4.8-1.8,4.8-5.4V0.4h4.9v12.7c0,6.8-3.8,10.1-9.9,10.1
				S111.3,19.8,111.3,13.2z"/>
			<path d="M134.4,19.6l2.9-3.5c2,1.7,4.1,2.7,6.7,2.7c2,0,3.2-0.8,3.2-2.1v-0.1c0-1.3-0.8-1.9-4.5-2.9c-4.5-1.2-7.4-2.4-7.4-6.9V6.8
				c0-4.1,3.3-6.8,7.9-6.8c3.3,0,6.1,1,8.3,2.9l-2.6,3.7c-2-1.4-3.9-2.2-5.8-2.2c-1.9,0-2.9,0.9-2.9,2v0.1c0,1.5,1,2,4.8,3
				c4.6,1.2,7.1,2.8,7.1,6.7v0.1c0,4.5-3.4,7-8.2,7C140.5,23.2,137.1,22,134.4,19.6z"/>
			<path d="M161.2,4.9h-6.8V0.4H173v4.6h-6.8v17.9h-4.9V4.9z"/>
			<path d="M174.8,11.7L174.8,11.7c0-6.4,5-11.7,12-11.7c6.9,0,11.9,5.2,11.9,11.6v0.1c0,6.4-5,11.6-12,11.6
				C179.8,23.2,174.8,18.1,174.8,11.7z M193.5,11.7L193.5,11.7c0-3.9-2.8-7.1-6.8-7.1c-4,0-6.7,3.1-6.7,7v0.1c0,3.9,2.8,7.1,6.8,7.1
				C190.8,18.7,193.5,15.5,193.5,11.7z"/>
			<path d="M203.1,0.4h5.3l5.9,9.5l5.9-9.5h5.3v22.5h-4.9V8.2l-6.3,9.6h-0.1l-6.3-9.5v14.6h-4.8V0.4z"/>
			<path d="M231,0.4h16.9v4.4h-12v4.6h10.6v4.4h-10.6v4.7h12.2v4.4H231V0.4z"/>
			<path d="M252.5,0.4h10.3c2.9,0,5.1,0.8,6.5,2.3c1.3,1.3,1.9,3,1.9,5.1v0.1c0,3.6-2,5.9-4.8,7l5.5,8h-5.8l-4.8-7.2h-3.9v7.2h-4.9
				V0.4z M262.4,11.3c2.4,0,3.8-1.3,3.8-3.2V8.1c0-2.1-1.5-3.2-3.9-3.2h-4.9v6.4H262.4z"/>
			<path d="M274.8,19.6l2.9-3.5c2,1.7,4.1,2.7,6.7,2.7c2,0,3.2-0.8,3.2-2.1v-0.1c0-1.3-0.8-1.9-4.5-2.9c-4.5-1.2-7.4-2.4-7.4-6.9V6.8
				c0-4.1,3.3-6.8,7.9-6.8c3.3,0,6.1,1,8.3,2.9l-2.6,3.7c-2-1.4-3.9-2.2-5.8-2.2c-1.9,0-2.9,0.9-2.9,2v0.1c0,1.5,1,2,4.8,3
				c4.6,1.2,7.1,2.8,7.1,6.7v0.1c0,4.5-3.4,7-8.2,7C280.9,23.2,277.5,22,274.8,19.6z"/>
		</g>
	</svg>
)
